import { education } from '../data';
import styles from './Education.module.css';

const Education = () => {
  return (
    <section className={styles.container}>
      {education.map(list => 
        <div className={styles.eduList}>
          <h3 className={styles.listTitle}>{list.listTitle}</h3>
          {list.samples.map(sample =>
            <div className={styles.eduCard}>
            <img src={sample.imgSrc} alt={sample.imgTitle}
            className={styles.edulogo}/>
            <div className={styles.cardDetails}>
              <h3>{sample.institution}</h3>
              <p>{sample.summary}</p>
              <p>{sample.details}</p>
            </div>
          </div>
          )}
        </div>
      )}
     
      <img src='/assets/EducationBG.PNG' alt=''className={styles.bgimg}/>
    </section>
  )
}

export default Education;