import { useState, useEffect } from 'react';
import { artProjects, writingProjects, linkProjects } from '../data';
import styles from '../Components/Portfolio.module.css';

const Portfolio = () => {
  const [listFilter, setListFilter] = useState('all');
  const filters = ["all", "illustration", "writing", "scriptwork", "dnd", "fanart", "software dev", "collabs"]

  const handleClick = (e:any) => {
    setListFilter(e.target.value);
  }

  useEffect(() => {
  }, [setListFilter])

  return (
    <div className={styles.container}>
      <ul className={styles.filterList}>
        <p>filters:</p>
        {filters.map(filter =>
          <button value={filter} onClick={handleClick} key={filter} className={listFilter === filter ? `${styles.filter} ${styles.selected}` : styles.filter}
          >{filter}
          </button>
        )}
      </ul>

      {/* ILLUSTRATION and DESIGN PORTFOLIO */}
      {artProjects.filter((projects) => 
      projects.tags.includes(listFilter)).map(project =>
      <section className={styles.list}>
        <h3 className={styles.listTitle}>{project.listTitle}</h3>
        {project.samples.map(sample =>
          <img src={sample.image} alt={sample.title} loading="lazy" className={styles.artCard}/>
        )}
      </section>)}


      {/* WRITING PORTFOLIO */}
      {writingProjects.filter((projects) => 
      projects.tags.includes(listFilter)).map(project => 
        <section className={styles.list}>
          <h3 className={styles.listTitle}>{project.listTitle}</h3>
          {project.samples.map(sample => 
            <div className={styles.pdfDiv}>
              <h4>{sample.title}</h4>
              <iframe src={sample.image} className={styles.pdfView} title={sample.title} />
            </div>
          )}
        </section>
      )}

      {/* LORE PORTFOLIO */}
      {linkProjects.filter((projects) => projects.tags.includes(listFilter)).map(project =>
        <section className={styles.list}>
          <h3 className={styles.listTitle}>{project.listTitle}</h3>
          {project.samples.map(sample => 
            <a href={sample.link} className={styles.artCard} style={{width:'20%'}} target="_blank" rel="noreferrer noopener">
              <img src={sample.image} alt={sample.title} loading='lazy' className={styles.loreimg} />
            </a>
          )}
        </section>
      )}
    </div>
  )
}

export default Portfolio;