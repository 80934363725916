import styles from './Home.module.css';

const Home = () => {
  return (
    <div className={styles.main}>
      <img src='/assets/DizzyBG.JPG' className={styles.bgPic} alt='Homepage BG' />
    </div>

  )
}

export default Home;