import styles from './Navbar.module.css';

const Navbar = () => {

  return (
    <header className={styles.container}>
      <h1>michaelgainey</h1>
      <div className={styles.linkList}>
        <a href="\" className={styles.links}>home</a>
        <a href="\portfolio" className={styles.links}>portfolio</a>
        <a href="\education" className={styles.links}>education</a>
        <a href="\teaching" className={styles.links}>teaching</a>
        <a href="\contact" className={styles.links}>contact</a>
      </div>
    </header>
  );
}

export default Navbar;