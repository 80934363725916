import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Portfolio from './Components/Portfolio';
import Education from './Components/Education';
import Teaching from './Components/Teaching';
import Contact from './Components/Contact';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path='/home' element={<Home />} />
          <Route path='/portfolio' element={<Portfolio />} />
          <Route path='/education' element={<Education />} />
          <Route path='/teaching' element={<Teaching />} />
          <Route path='/contact' element={<Contact />} />
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
        <footer>
          Copyright &#169; | All rights reserved
        </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
