export const studentEvals = [
  "\"Amazing professor who cares about his students. Made the class even more enjoyabble!! :)\"",

  "\"Best instructor ever. So friendly and patient, and I really enjoyed his classes.\"",

  "\"I liked how he gave detailed answers to student's questions. When I would answer discussion questions, they might have seemed like a stretch. But Gainey would always show how the answers given could be connected back to the current discussion topic.\"",

  "\"Mike was wonderful. Extremely friendly, charismatic, and enthusiastic about the course material. He made me feel welcomed in class every day and never made it a stressful environment.\"",
  "\"Mr. Gainey is an excellent teacher and WCWP 10B was one of my favorite classes that I took this spring quarter.\"",

  "\"He was a great instructor and I learned a lot - very helpful and friendly.\"",

  "\"Mike Gainey is a fantastic instructor that genuinely cares about the success of his students. Live sections are engaging and he knows how to facilitate group discussion very well, even when the class is unresponsive. Written and verbal feedback are personalized and thorough, genuinely helpful.\"",

  "\"Mr. Gainey was an excellent teacher. I learned a lot about writing effectively from him, and could not have asked for a better instructor.\"",

  "\"Mike Gainey is very easy to talk to, he knows how to listen and make the correct comments to nudge you in the correct direction for how to write your papers.\"",

  "\"Honestly loved Mike as an instructor, he did a great job in many ways. One of the things I liked most was his ability to stimulate thought provoking, yet open minded discussion that accepted all views, and probed people to think more about their stance to create a defendable argument.\"",

  "\"Even when the class was quiet, he was still calm and trying to lead the class for discussion. He's also by far the most chill guy teaching here at UCSD. His whole vibe makes for a easy feeling class.\"",

  "\"Literally such a good IA.\"",

  "\"AMAZING MAN 10/10\"",

  "\"Mike is great. He's a kind and effective IA.\"",

  "\"Mike was kind and helpful. He encouraged class participation and discussions were engaging. He showed he cared by taking the time to remember our names on the first day and then always asking us about our opinions. He stimulated class conversation very well.\""
];