export const artProjects = [
  {
      listTitle: "single panel",
      tags: ['all', 'illustration'],
      samples: [
        {
          title: "Skyscraper Pinata",
          image: "/assets/nyer/SkyscraperPinata.PNG",
        },
        {
          title: "Bakeoff Boxer",
          image: "/assets/nyer/BakeoffBoxer.PNG",
        },
        {
          title: "Millenial Weight Blanket",
          image: "/assets/nyer/MillenialWeightBlanket.PNG",
        },
        {
          title: "Ant Waiter",
          image: "/assets/nyer/AntWaiter.PNG",
        },
        {
          title: "Good Enough",
          image: "/assets/nyer/GoodEnough.png",
        },
        {
          title: "Handgun",
          image: "/assets/nyer/Handgun.png",
        },
        {
          title: "Cowlick Dracula",
          image: "/assets/nyer/CowlickDracula.PNG",
        },
        {
          title: "Critical Bogeyman",
          image: "/assets/nyer/CriticalBogeyman.PNG",
        },
        {
          title: "Lets Never",
          image: "/assets/nyer/LetsNever.PNG",
        },
        {
          title: "Metal Todds Dad",
          image: "/assets/nyer/MetalToddsDad.PNG",
        },
        {
          title: "Plain Vanilla",
          image: "/assets/nyer/PlainVanilla.png",
        },
        {
          title: "Soul Pets",
          image: "/assets/nyer/SoulPets.PNG",
        },
        {
          title: "Microplastics",
          image: "/assets/nyer/Microplastics.png",
        },
        {
          title: "Spider Lawyer",
          image: "/assets/nyer/SpiderLawyer.PNG",
        },
        {
          title: "Swipe Face",
          image: "/assets/nyer/SwipeFace.PNG",
        },
        {
          title: "Lady of the Lake",
          image: "/assets/nyer/LadyOfTheLake.png",
        },
        {
          title: "Curriculum Inhibitor",
          image: "/assets/nyer/CurriculumInhibitor.PNG",
        },
        {
          title: "Frankensteins Provider",
          image: "/assets/nyer/FrankensteinsProvider.PNG",
        },
        {
          title: "That Pontiac",
          image: "/assets/nyer/ThatPontiac.PNG",
        },
        {
          title: "Community Chest",
          image: "/assets/nyer/CommunityChest.png",
        },
        {
          title: "Uncle Sam Overlords",
          image: "/assets/nyer/UncleSamOverlords.PNG",
        },
        {
          title: "Victim Vacation",
          image: "/assets/nyer/VictimVacation.PNG",
        },
        {
          title: "Werewolf Haircut",
          image: "/assets/nyer/WerewolfHaircut.PNG",
        },
        {
          title: "You Were Saying",
          image: "/assets/nyer/YouWereSaying.PNG",
        },
      ]
  },
  {
      listTitle: "dnd comics",
      tags: ['all', 'illustration', 'dnd'],
      samples: [
        {
          title: "Animal Friendship",
          image: "/assets/dndcomics/AnimalFriendship.PNG",
        },
        {
          title: "Hunters Mark Meeting",
          image: "/assets/dndcomics/HuntersMarkMeeting.PNG",
        },
        {
          title: "Lesser Restoration Weekend",
          image: "/assets/dndcomics/LessRestorationWeekend.PNG",
        },
        {
          title: "Mage Hand Dad",
          image: "/assets/dndcomics/MageHandDad.PNG",
        },
        {
          title: "Persuasion Check Interview",
          image: "/assets/dndcomics/PersuasionCheckInterview.PNG",
        },
        {
          title: "Toddler Identify",
          image: "/assets/dndcomics/ToddlerIdentify.PNG",
        },
      ]
  },
  {
      listTitle: "random fan art",
      tags: ['all', 'fanart', 'illustration'],
      samples: [
        {
          title: "Greninja Runway",
          image: "/assets/fanart/GreninjaRunway.png",
        },
        {
          title: "Pikachu Garage",
          image: "/assets/fanart/PikachuGarage.png",
        },
        // {
        //   title: "Pumpkin Thing",
        //   image: "/assets/fanart/PumpkinThing.png",
        // },
      ]
  },
  {
      listTitle: "webcomic: it came from hugo pines!",
      tags: ['all', 'illustration'],
      samples: [
        {
          title: "Chapter 05",
          image: "/assets/hugopines/ICFHP_ch05.PNG",
        },
        {
          title: "Chapter 06",
          image: "/assets/hugopines/ICFHP_ch06.PNG",
        },
        {
          title: "Chapter 08",
          image: "/assets/hugopines/ICFHP_ch08.PNG",
        },
        {
          title: "Chapter 12",
          image: "/assets/hugopines/ICFHP_ch12.PNG",
        },
        {
          title: "Chapter 38",
          image: "/assets/hugopines/ICFHP_ch38.PNG",
        },
        {
          title: "Chapter 39",
          image: "/assets/hugopines/ICFHP_ch39.PNG",
        },
        {
          title: "Chapter 40",
          image: "/assets/hugopines/ICFHP_ch40.PNG",
        },
        {
          title: "Chapter 44",
          image: "/assets/hugopines/ICFHP_ch44.PNG",
        },
      ]
  },
  {
      listTitle: "software dev & ui design",
      tags: ['all', 'software dev'],
      samples: [
        {
          title: "Vakay!",
          image: "/assets/UIwork/VakayUI.png",
        },
        {
          title: "Emotional Wellbeing Lab",
          image: "/assets/UIwork/ewb-lab-UI.png",
        },
        {
          title: "5e Monster Manual",
          image: "/assets/UIwork/MonsterManual-UI.png",
        },
        {
          title: "Subscriber",
          image: "/assets/UIwork/SubscriberUI.png",
        },
        {
          title: "ifGift",
          image: "/assets/UIwork/ifGiftUI.png",
        },
      ]
  },
  {
      listTitle: "logo design",
      tags: ['all', 'software dev'],
      samples: [
        {
          title: "Vakay!",
          image: "/assets/logos/VakayLogo.png",
        },
        {
          title: "Subscriber",
          image: "/assets/logos/SubscriberLogo.png",
        },
        {
          title: "ifGift",
          image: "/assets/logos/ifGiftLogo.png",
        },
      ]
  },
];


export const writingProjects = [
  {
    listTitle: "pilot scripting",
    tags: ['all', 'scriptwork', 'writing'],
    samples: [
      {
        title: "fantastic four spec pilot",
        image: "/assets/scripts/FFpilot.pdf",
      },
      {
        title: "the book was better",
        image: "/assets/scripts/BookWasBetter.pdf"
      }
    ]
  },
  {
    listTitle: "comic spec scripting",
    tags: ['all', 'scriptwork', 'writing'],
    samples: [
      {
        title: "nessie frump, galactic local 918",
        image: "/assets/scripts/NessieFrump.pdf",
      },
      {
        title: "the red heresy",
        image: "/assets/scripts/TheRedHeresy.pdf",
      },
      {
        title: "in deep space",
        image: "/assets/scripts/InDeepSpace.pdf",
      },
      {
        title: "cleaning the attic",
        image: "/assets/scripts/CleaningTheAttic.pdf",
      },
      {
        title: "delays in parenting",
        image: "/assets/scripts/DelaysInParenting.pdf",
      },
      {
        title: "middle management",
        image: "/assets/scripts/MiddleManagement.pdf",
      },
    ]
  },
  {
    listTitle: "old sketch samples",
    tags: ['all', 'scriptwork', 'writing'],
    samples: [
      {
        title: "friendzone sketches",
        image: "/assets/scripts/FZsketches.pdf",
      },
      {
        title: "torture sketch",
        image: "/assets/scripts/TortureSketch.pdf"
      },
      {
        title: "in a relationship sketch",
        image: "/assets/scripts/InARelationshipSketch.pdf"
      },
      // {
      //   title: "daycare noir sketch",
      //   image: "/assets/scripts/DayCareNoirSketch.pdf"
      // },
    ]
  },
  {
    listTitle: "game show scripting",
    tags: ['all', 'scriptwork', 'writing'],
    samples: [
      {
        title: "slingtv multiplayer eps 5-6",
        image: "/assets/scripts/MULTIPLAYEReps5-6.pdf",
      },
      {
        title: "slingtv multiplayer eps 7-8",
        image: "/assets/scripts/MULTIPLAYEReps7-8.pdf",
      },
      {
        title: "slingtv multiplayer eps 9-10",
        image: "/assets/scripts/MULTIPLAYEReps9-10.pdf",
      },
    ]
  },
  {
    listTitle: "comic collaborations",
    tags: ['all', 'collabs', 'writing'],
    samples: [
      {
        title: "first in morale",
        image: "/assets/comiccollabs/FirstInMorale.pdf",
      },
      {
        title: "consequential",
        image: "/assets/comiccollabs/Consequential.pdf",
      },
      {
        title: "mr. monster, art by jess reilly",
        image: "/assets/comiccollabs/MrMonster.pdf",
      }
    ]
  }
];


export const linkProjects = [
  {
    listTitle: "animation scripting: lore in a minute",
    tags: ['all', 'writing', 'collabs'],
    samples: [
      {
        title: "forgotten realms lore video link",
        image: "/assets/anishorts/FRlore.jpg",
        link: "https://www.youtube.com/watch?v=_MC--auSi-w"
      },
      {
        title: "futurama lore video link",
        image: "/assets/anishorts/FUTlore.jpg",
        link: "https://www.youtube.com/watch?v=Sd1qz16fhwk&t=17s"
      },
      {
        title: "jojos bizarre adventure lore video link",
        image: "/assets/anishorts/JJBAlore.jpg",
        link: "https://www.youtube.com/watch?v=v2wGZBjvcvo"
      },
      {
        title: "katamari damacy lore video link",
        image: "/assets/anishorts/KDlore.jpg",
        link: "https://www.youtube.com/watch?v=RdkkSYqClyk"
      },
      {
        title: "mortal kombat x lore video link",
        image: "/assets/anishorts/MKXlore.jpg",
        link: "https://www.youtube.com/watch?v=b052IbtKaKs"
      },
      {
        title: "mobile suit gundam lore video link",
        image: "/assets/anishorts/MSGlore.jpg",
        link: "https://www.youtube.com/watch?v=ItRwkMDI8Qg"
      },
      {
        title: "one punch man lore video link",
        image: "/assets/anishorts/OPMlore.jpg",
        link: "https://www.youtube.com/watch?v=NSWtFS4nwuA"
      },
      {
        title: "gurren lagann lore video link",
        image: "/assets/anishorts/TTGLlore.jpg",
        link: "https://www.youtube.com/watch?v=erDrgWIJkbw&t=3s"
      },
      {
        title: "yu-gi-oh lore video link",
        image: "/assets/anishorts/YGOlore.jpg",
        link: "https://www.youtube.com/watch?v=osfWvaRAuM4&t=16s"
      }
    ]
  }
];


export const education = [
  {
    listTitle: "university education",
    samples: [
      {
        institution: "University of California, San Diego",
        imgSrc: "/assets/eduicons/UCSDlogo.png",
        imgTitle: "UCSD logo",
        summary: "Currently Enrolled",
        details: "Department of Communication PhD Program",
      },
      {
        institution: "Ithaca College",
        imgSrc: "/assets/eduicons/IClogo.jpg",
        imgTitle: "IC logo",
        summary: "Bachelor's of Science",
        details: "Communications, Television / Radio",
      },
    ]
  },
  {
    listTitle: "creative education",
    samples: [
      {
        institution: "Upright Citizens Brigade",
        imgSrc: "/assets/eduicons/UCBlogo.jpg",
        imgTitle: "UCB logo",
        summary: "Sketch Writing 101 and 102",
        details: "Instructor: Neil Campbell",
      },
      {
        institution: "La Jolla Athenaeum",
        imgSrc: "/assets/eduicons/LJAlogo.png",
        imgTitle: "Athenaeum logo",
        summary: "Quick Sketch Life Drawing",
        details: "Instructor: Marty Davis",
      },
      {
        institution: "Feminist Comic Book Club of San Diego",
        imgSrc: "/assets/eduicons/FCBClogo.jpg",
        imgTitle: "FCBC logo",
        summary: "Weekly Creative Workshop (2016-present)",
        details: "Organizer: Michael Gainey",
      },
    ]
  },
  {
    listTitle: "software development",
    samples: [
      {
        institution: "Codeworks.me",
        imgSrc: "/assets/eduicons/CWlogo.png",
        imgTitle: "Codeworks logo",
        summary: "Software Development Immersive",
        details: "A competitive program with a 3% acceptance rate.",
      },
      {
        institution: "AlgoExpert.io",
        imgSrc: "/assets/eduicons/AElogo.jpg",
        imgTitle: "AlgoExpert logo",
        summary: "FrontendExpert Certification",
        details: "ID: a35557c297",
      },
    ]
  },
  {
    listTitle: "miscellaneous",
    samples: [
      {
        institution: "Wild Willow Farm and Education Center",
        imgSrc: "/assets/eduicons/WWFEClogo.jpg",
        imgTitle: "Wild Willow Farm logo",
        summary: "Regenerative Farming 101 and 102",
        details: "Instructor: Paul Maschka",
      },
    ]
  },
];