import Marquee from 'react-fast-marquee';
import { studentEvals } from '../studentEvals';
import styles from './Teaching.module.css';

const Teaching = () => {
  return (
    <section className={styles.container}>
      <h3 className={styles.listTitle}>lecturing</h3>
      <div className={styles.teachCard}>
        <div className={styles.schoolDeets}>
          <img src='/assets/eduicons/UCSDlogo.png' alt='UCSD logo' className={styles.logo} />
          <h3>University of California, San Diego</h3>
          <h4>Warren College Writing Program</h4>
          <h4>Lecturer and Departmental Tutor</h4>
        </div>
        <div className={styles.jobDeets}>
          <h5>Courses Taught:</h5>
          <ul>
            <li>WCWP 10A: Climate Change Ethics</li>
            <li>WCWP 10A: Promises and Contradictions in American History</li>
            <li>WCWP 10B: Justice in American History</li>
            <li>WCWP 10B: Food Ethics</li>
          </ul>
          <h5>Courses Developed and Taught:</h5>
          <ul>
            <li>WCWP 10B: Disney Animation and Ideology</li>
          </ul>
        </div>
      </div>

      <div className={styles.slideContainer}>
        <h3 className={styles.listTitle}>sample lecture calendar & slides</h3>
        <div className={styles.slideList}>
          <iframe src="/assets/10BDisneyCalendar.pdf" title='DAIcal' className={styles.slides} />
          <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vT0GaY4UrH2IU16uNCu4neScgIfAH9TIA1_JLUuQi5Xohs4o_97826Cwz9LZmkotg/embed?start=false&loop=false&delayms=3000" title='DAIwk2' className={styles.slides} />
          <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vTZdLM9D9DrYRS-SXed_1ZiYptp3Z-fIWzfd5T9DdQ1i-wDJ8-rucsl4lzvHFQvfA/embed?start=false&loop=false&delayms=3000" title='DAIwk4' className={styles.slides} />
        </div>
      </div>

      <div className={styles.evalContainer}>
        <h3 className={styles.listTitle}>student evaluations</h3>
        <div className={styles.evalTicker}>
          <img src="/assets/EvalFace.PNG" alt="Surprised Cartoon" className={styles.evalImg}/>
          <Marquee className={styles.marquee} speed={35} pauseOnHover={true}>
            {studentEvals.map(studentEval => 
              <p className={styles.marqueeText}>{studentEval}</p>
            )}
          </Marquee>
        </div>
      </div>
      

    </section>
    
  )
}

export default Teaching;